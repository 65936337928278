/* eslint-disable react/no-unknown-property */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import * as React from "react"
import { useForm } from "react-hook-form"
import { css } from "@emotion/react"
import * as yup from "yup"
import * as hookform from "@hookform/resolvers"
import { generateFieldValidation } from "components/Form/utils"
import { FieldTypes } from "components/Form/FormField"
import hubspot from "scripts/hubspot"
import ErrorLabel from "components/Form/ErrorLabel"
import TextField from "components/Form/TextField"
import Link from "components/Link"
import styled from "@emotion/styled"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import Checkbox from "./Checkbox"
import { H5, P } from "../headings"
import Button, { ButtonBase } from "../button"
import { theme } from "../../common/colorScheme"
import { SectionThemeContext } from "../sections/SectionThemeContext"


const FormBase = styled.form<{ gradient: string }>`
	width: 100%;
	max-width: 1288px;
	display: flex;
	flex-direction: column;
	background: ${({ gradient }) => gradient ? `linear-gradient(41deg, ${gradient})` : `linear-gradient(41deg, #371074 0%, #7122da 100%)`};
	border-radius: 8px;
	padding: 52px 36px 44px;
	color: #fff;
	margin:  0 auto 48px;

	position: relative;
	${H5} {
		color: #fff;
	}
	${P} {
		color: #fff;
		font-size: 1.6rem;
		line-height: 2rem;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		${H5} {
			font-size: 2.8rem;
		}
		padding: 42px 32px 42px;
		box-shadow: 7px 7px 18px 0 rgba(35, 35, 35, 0.1);
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
	${ButtonBase}[data-variant="primary"] {
		background: rgba(255, 255, 255, 1);
		color: var(--main-color);
		&:hover {
			background: rgba(255, 255, 255, 0.9) !important;
		}
	}
	&[aria-busy="true"] {
		pointer-events: none;
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: var(--main-color);
			opacity: 0.3;
		}
	}
	${ButtonBase}[data-variant="black"] {
		background: black;
		&:hover {
			color: white;
			background: var(--main-color)!important;
		}
	}
	&[aria-busy="true"] {
		pointer-events: none;
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: var(--main-color);
			opacity: 0.3;
		}
	}
`

const FormTitle = styled.h3`
	font-weight: 800;
	font-size: 2em;
	margin-bottom: 0.3em;

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 3.2em;
	}
`

const FormCenter = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		width: calc(100% / 3);
	}
`

const FormRight = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	${mediaBreakpoint(BreakPoints.MD)} {
		width: calc(100% / 3);
	}
`

const FormRightContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	${mediaBreakpoint(BreakPoints.MD)} {
		max-width: 220px;
		margin-left: 30px;
	}
`

const submitStyles = css`
	margin: 16px 0 0;
	min-width: 192px;
	width: 100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		width: auto;
	}
	&:hover {
		background: rgba(255, 255, 255, 0.9) !important;
	}
`

const MessageOverlay = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 3;
	position: absolute;
	background: linear-gradient(41deg, #371074 0%, #7122da 100%);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 32px;
`

const MessageOverlayText = styled.div`
	max-width: 600px;
	font-size: 2rem;
	line-height: 2.8rem;
	text-align: center;
	a {
		color: #fff;
		font-weight: bold;
	}
`

const FormLeft = styled.div`
	width: 100%;

	${mediaBreakpoint(BreakPoints.MD)} {
		width: calc(100% / 3 - min(10%, 70px));
		margin-right: min(10%, 70px);
	}
`

const fieldGroup = css`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	${mediaBreakpoint(BreakPoints.MD)} {
		margin-right: ${theme.spacing(2)};
	}

	input {
		margin: ${theme.spacing(1, 0)};
	}
`

const successWrapperStyles = css`
	display: flex;
	align-items: center;
		span {
			color: white;
		}

	${mediaBreakpoint(BreakPoints.LG)} {
		height: 136px;
	}
`

const privacyLabelStyle = css`
	margin: ${theme.spacing(1, 0)};
`

interface FormData {
	firstName: string
	email: string
	termsAccepted: boolean
	fullName?: string
	workEmail?: string
	company?: string
}

const privacyLabel = (
	<div css={privacyLabelStyle}>
		I accept the
		<Link to="https://www.latana.com/legal-terms/privacy-policy">Privacy Policy</Link>
	</div>
)

const defaultFormId = "newsletter"
const title = "Free Brand Insights and Tips"
const defaultButtonLabel = "Get My Insights"
const defaultHubspotId = "f1cc1e65-421b-48cb-860c-ec3ed5a407d0"
const defaultDescription = "Sign up for our Newsletter to receive free, insightful tips on all things brand!"

const defaultFields = [
	{
		id: Math.random(),
		type: FieldTypes.TextField,
		fieldName: 'firstName',
		label: 'First Name',
		required: true,
		options: ['Please enter your full name']
	},
	{
		id: Math.random(),
		type: FieldTypes.EmailField,
		fieldName: 'email',
		label: 'Work Email',
		required: true,
		options: ['Please enter a valid email address']
	}
]

const NewsletterForm = ({
	contentfulfields = defaultFields,
	hubspotFormId = defaultHubspotId,
	formId = defaultFormId,
	bannerTitle = title,
	submitText = defaultButtonLabel,
	buttonVariant,
	bannerSubtitle,
	bannerGradient
}) => {
	interface SubmitMessage {
		type: "success" | "error"
		message: string
	}

	const [pending, setPending] = React.useState(false)
	const [submitted, setSubmitted] = React.useState<SubmitMessage>()

	const schema = React.useMemo(() => {
		const obj = {}
		for (const item of contentfulfields) {
			obj[item.fieldName] = generateFieldValidation({
				type: item.type,
				fieldName: item.fieldName,
				required: item.required,
				message: item?.options?.[0] || 'Please, enter correct value',
			})
		}
		return yup.object({ ...obj, termsAccepted: yup.boolean().oneOf([true], "Please mark the checkbox") })
	},[contentfulfields])

	const defaultValues = React.useMemo(() => {
		const values = {}
		for (const item of contentfulfields) {
			values[item.fieldName] = ''
		}
		return { ...values, termsAccepted: false } as FormData
	},[contentfulfields])

	const { register, handleSubmit, errors, setError } = useForm({
		defaultValues,
		resolver: hookform.yupResolver(schema),
	})

	const onSubmit = React.useCallback(async (formData: FormData) => {
		setPending(true)
		try {
			await hubspot.submitForm(hubspotFormId, formData)

			const dataLayer = (window as any).dataLayer || []
			dataLayer.push({ event: formId })

			const attribution = (window as any).Attribution || { identify: () => null, track: () => null }

			attribution.identify(formData)

			attribution.track("form submitted", {
				id: formId,
			})
			const { inlineMessage } = await hubspot.submitForm(hubspotFormId, formData)
			setSubmitted({
				type: "success",
				message: inlineMessage,
			})
		} catch (error) {
			setError("email", {
				message: "Please enter a valid business email",
			})
		}
		setPending(false)
	}, [])

	console.log(errors)

	return  (
		<SectionThemeContext.Provider value="default">
			<FormBase
				id={formId}
				onSubmit={handleSubmit(onSubmit)}
				aria-busy={pending}
				gradient={bannerGradient}
			>
				<FormLeft>
					<FormTitle>{bannerTitle || title}</FormTitle>
					{bannerSubtitle || defaultDescription ? <P>{bannerSubtitle || defaultDescription}</P> : null}
				</FormLeft>
				<FormCenter>
					{contentfulfields.map(({ id, type, fieldName, label }) => (
						<div key={id} css={fieldGroup}>
							<TextField
								type={type}
								name={fieldName}
								placeholder={label}
								data-invalid={Boolean(errors[fieldName])}
								ref={register}
							/>
							{errors[fieldName] && <ErrorLabel>{errors[fieldName].message}</ErrorLabel>}
						</div>
					))}
				</FormCenter>
				<FormRight>
					<FormRightContainer>
						<Button id="newsletter-submit" label={submitText} type="submit" variant={buttonVariant || "primary-new" as any} css={submitStyles} />
						<Checkbox name="termsAccepted" ref={register} label={privacyLabel} data-invalid={Boolean(errors.termsAccepted)} />
						{errors.termsAccepted && <ErrorLabel>{errors.termsAccepted.message}</ErrorLabel>}
					</FormRightContainer>
				</FormRight>
				{submitted ? (
					<MessageOverlay>
						<MessageOverlayText>							
							<div css={successWrapperStyles}>
								<MessageOverlayText
									dangerouslySetInnerHTML={{
										__html: submitted.message,
									}}
								/>
							</div>
						</MessageOverlayText>
					</MessageOverlay>
				) : null}
			</FormBase>
		</SectionThemeContext.Provider>
	)
}

export default React.memo(NewsletterForm)